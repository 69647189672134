/* overall structure */


.mainebox{width: 100%; display: flex; background: #fff; overflow: hidden;}
.leftpart{width: 75px; height: 100vh; transition: all .3s ease-in-out; background: var(--primary);}
/* .leftpart:hover{width: 350px;} */
.rightpart{width: 100%; height: 100vh; float: right; transition: all .3s ease-in-out}
.rightheader{width: 100%; border-bottom: 1px solid #DAE6FC; }
.menuopened .rightpart{width:calc(100% - 280px); }
.mainpart{ height: 100%; overflow: auto; padding:  0 40px;}
.leftpartcollapes{width: 350px;}


/* ------------------------- left navigation ---------------------- */

.menupart{width: 100%; position: relative; height: 100%;}
.menupart .logonav{width: 100%; text-align: center; height: 35px; margin: 8px 10px; padding-left: 0px; outline:none; cursor: pointer;}
.menupart .navigation ul li i.menuicon{visibility: visible; opacity: 1; font-size: 18px; color:#12ffd0 ;}
.menupart .navigation{width: 100%; text-align: center; padding: 20px 0px; flex-direction: column;
  height: calc(100% - 51px); display: flex;
  justify-content: space-between;}
.menupart .navigation ul{list-style: none; margin: 0; padding: 0;}
.menupart .navigation ul li{  cursor: pointer; position: relative;}
.menupart .navigation ul li .menuarea{display: flex; transition: all .3s ease-in-out; align-items: center; outline: none; padding: 13px 18px; border-left:3px solid transparent; position: relative;}
.menupart .navigation ul li .menuarea:focus{outline: none;}
.menupart .navigation ul li .menuarea img{width: 25px; height: 25px; margin: 0 4px; filter: brightness(0);}
.menupart .navigation ul li p{margin: 0; padding-left: 10px; font-size:14px; font-family: 'NuberNext-Regular', sans-serif; color: var(--black); visibility: hidden; opacity: 0; transition:all .3s ease-in-out; white-space: nowrap;}
.menupart .navigation ul li i{visibility: hidden; opacity: 0; transition:all .3s ease-in-out;     position: absolute; right: 15px; font-size: 10px;}
.menupart .navigation ul li:hover, .menupart .navigation ul li.active{ background:var(--black)  ; color:#fff}
.menupart .navigation ul li:hover img, .menupart .navigation ul li.active img{filter: brightness(1);}
.menupart .navigation ul li:hover p, .menupart .navigation ul li.active p{ color:#fff}
/* .menupart .navigation ul li .menuarea:hover img, .menupart .navigation ul li .menuarea.active img{filter: grayscale(0);} */

/* side menu activate */
.leftpart.leftpartcollapes .menupart .navigation ul li p, .leftpart.leftpartcollapes .menupart .navigation ul li i{visibility: visible; opacity: 1;}

.closemenum{width: 24px; height: 24px; padding-left: 0px; opacity: 0; visibility: hidden; transition: all .2s ease-in-out; cursor: pointer;}
.leftpart.leftpartcollapes .closemenum{visibility: visible; opacity: 1;}
/* side menu activate */
.closemenum img{width:100%}
.menupart .navigation ul li .customedropdownmenu{background:var(--primary); position: absolute; left: 100%; width: 250px; top: 0; border-radius: 10px; visibility: hidden; opacity: 0; z-index: 2;  box-shadow: 0px 0px 32px -11px rgba(0, 0, 0, 0.256); padding: 5px 0;}
.menupart .navigation ul li .customedropdownmenu li{padding: 0px 20px; text-align: left; font-size: 14px; font-weight: 600; color: var(--black); outline: none; margin-bottom: 5px; transition: all .3s ease-in-out;}
.menupart .navigation ul li .customedropdownmenu li i{opacity: 1; visibility: visible; position: inherit;     left: auto;
  right: auto;}
.menupart .navigation ul li .customedropdownmenu li:nth-last-child(1){border-bottom:0}
.arrowbox{width: 15px; height: 15px; background: var(--primary); position: absolute; left: -7px; top: 17.5px;
transform: rotate(45deg);}


.menupart .navigation ul li .customedropdownmenu li:hover{color: var(--blue2); transform: translateX(5px);}

/* side menu activate */
.leftpart.leftpartcollapes .menupart .navigation ul li .customedropdownmenu{ position: initial; visibility: visible; opacity: 1; display: none;     width: 100%;     box-shadow: none;
  padding-left: 50px; margin: 0px auto; border-radius: 0;}
.leftpart.leftpartcollapes .menupart .navigation ul li .customedropdownmenu .arrowbox{display: none;}
.leftpart.leftpartcollapes .menupart .navigation ul li .customedropdownmenu li{font-size: 12px; font-weight: 500; white-space: pre; background: none;}
.leftpart .menupart .navigation ul li .customedropdownmenu li{background: none;}
.leftpart.leftpartcollapes .menupart .navigation ul li .customedropdownmenu h6{display: none;}
.leftpart.leftpartcollapes .menupart .navigation ul li .customedropdownmenu.dashcolll{display: none !important;}
.leftpart.leftpartcollapes .menupart .navigation ul li .customedropdownmenu.show{display: block;}
/* side menu activate */
.leftpart.leftpartcollapes .menupart .navigation ul li .customedropdownmenu.dropMenuOpened{display: block !important; visibility: visible !important; opacity: 1 !important; }
.leftpart.leftpartcollapes .menupart .navigation ul li.active .customedropdownmenu.dropMenu{display: block !important; visibility: visible !important; opacity: 1 !important; background: #000;}

.leftpart.leftpartcollapes .menupart .navigation ul li:hover .customedropdownmenu.dropMenu{background: #000 !important;}
.leftpart.leftpartcollapes .menupart .navigation ul li:hover .customedropdownmenu.dropMenu ul li{color: var(--white) !important;}

.menupart .navigation ul li .menuarea:hover+.customedropdownmenu, .menupart .navigation ul li .customedropdownmenu:hover{visibility: visible; opacity: 1; }

.leftpart.leftpartcollapes .menupart .navigation ul li.active .customedropdownmenu li{
  color: var(--white) !important;
}
.menupart .navigation ul li .customedropdownmenu li{color: #000 !important;}

.customedropdownmenu h6{color: var(--black); text-align: left; padding: 10px 0 0 15px; letter-spacing: 1px; font-weight: 600;}
.leftpart .logout{ padding: 15px 17px; bottom: 50px;cursor:pointer; }
    .leftpart .logout .logoutp{margin-bottom: 0; color:#fff; padding-top: 3px; visibility:hidden; opacity: 0; transition: all .3s ease-in-out;}   
    .leftpart .logout img{width: 25px; height: 25px; margin-right: 10px; filter: brightness(0);}


    .leftpart.leftpartcollapes .logout  p{ visibility:visible; opacity: 1}
    .logout .logoutinn{width: 23px; display: flex; align-items: center;}
    .logout .customedropdownmenu{position: absolute; background: #fff;
        position: absolute; left: 100%; width: 150px; top: 2px; border-radius: 10px; z-index: 2; box-shadow: 0px 0px 32px -11px #bdbdbd; padding: 5px 0;
        right: 40px; opacity: 0; visibility: hidden; transition: all .3s ease-in-out;}
    .leftpart .logout:hover .customedropdownmenu{opacity: 1; visibility: visible;}
    .leftpart.leftpartcollapes .logout .customedropdownmenu{display: none;}


.logosmall{opacity: 1; visibility: visible; transition: all .3s ease-in-out;  }
.logobig{opacity: 0; visibility: hidden; transition: all .3s ease-in-out; }
    .leftpart.leftpartcollapes  .logobig{opacity: 1; visibility: visible; }
    .leftpart.leftpartcollapes  .logosmall{opacity: 0; visibility: hidden; }


.menuExtande{width: 40px; height: 40px; position: absolute; background: #ffd166; border-radius: 0 5px 5px 0; top: 2px; left: 100%; display: flex; align-items: center; justify-content: center; cursor: pointer;}


/* -----------------------top navigation ----------------- */
.usersbx{position: relative;}
.usersbx .navuser{display: flex; align-items: center; }
.usersbx .navuser .userimg{width: 30px; height: 30px; display:inline-table;  border-radius: 30px;}
.usersbx .navuser .userimg img{width:100%; margin: 0;}
.usersbx .navuser img{width: 17px; margin-left: 15px;}
.usersbx .user-details{width: 200px; z-index: 2;  right: 0;  position: absolute; opacity: 0; visibility: hidden; display: none;     }
.usersbx .user-details .userdetailsinn{position: relative; width: 100%; height: 200px; background: #fff; box-shadow: 0px 4px 10px #d6d6d6; border-radius: 5px; margin-top: 15px;  transition: all .3s ease-in-out; padding: 15px;}
.usersbx .user-details .userdetailsinn h6{font-size: 12px; font-weight: bold; margin-bottom: 2px; color: var(--aaction_box);}
.usersbx .user-details .userdetailsinn h5{font-size: 15px; font-weight: bold; margin-bottom: 0px; }
.usersbx .user-details .userdetailsinn .accountid{font-size: 12px; color: var(--line_colr); font-weight: 600; text-transform: uppercase; margin-bottom: 5px;}
.usersbx .user-details .userdetailsinn ul{list-style: none; margin: 0; padding: 0; border-top: 1px solid var(--line_colr); padding-top: 10px;}
.usersbx .user-details .userdetailsinn ul li a{color: #000; font-weight: 500; font-size: 14px; text-decoration: none; transition: all .3s ease-in-out;}
.usersbx .user-details .userdetailsinn ul li a i{font-size: 12px; color:var(--line_colr);}
.usersbx .user-details .userdetailsinn::before{content: ''; width: 13px; height: 13px; background: #d29c3c; transform: rotate(45deg);  position: absolute; right: 51px; top: -7px; z-index: -1;}
.borderright{border-right:1px solid var(--text_color_light); position: relative;}
.notificationblink{background: #ff0000; width: 5px; height: 5px; top: 6px; border-radius: 5px; position: absolute;
    right: 19px;}
.notificatios{width: 20px; float: right; margin-right: 5px; }
.usersbx:hover .user-details{opacity: 1; visibility: visible; display: block;}
.navbarbtn{width: 20px; height: 20px; cursor: pointer; }
.customewidthtab{margin: 10px 0;}

@media (min-width:270px) and (max-width:319px){
  .menupart{width: 100% !important;}
  .menuopened .rightpart{width:100%; }

}
@media only screen and (min-width:270px) and  (max-width:550px) {
.menupart .navigation ul li p{padding-left: 15px; font-size: 15px;}
.menuopened .rightpart{width:100%; }
}

@media only screen and (min-width:900px) and (max-width:1200){
  .customewidthtab{width: 13%;}
  .menuopened .rightpart{width:100%; }
}


@media only screen and (min-width:270px) and (max-width:767px){
.mainebox{height: 100vh; overflow: auto;}  
.leftpart{width: 100%; position: absolute; backdrop-filter: blur(5px); z-index: 2; left: -100%; background: none;}
.leftpartcollapes{left: 0; width: 100%;}
/* .leftpart:hover{width: 100%;} */
.mainpart{padding: 13px;}
.rightpart{height:100%;}

/* .menupart{overflow: auto;} */
.logout{position: initial;}
.closemenum{display: block;}
.menupart{background: var(--primary); width: 75%;}
.menupart .navigation{padding: 10px 0;}
.menuExtande{top: 10px;}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter{
  text-align: left !important;
}
}
@media only screen and (min-width:481px) and (max-width:767px){
  table.dataTable thead td{width:auto !important}
.menupart{background: var(--primary); width: 45%; }
.navigation{height: 100% !important; overflow: auto;}


}


@media only screen and (min-width:768px) and  (max-width:1028px) {
.leftpart{width: 75px;}
.leftpartcollapes{width: 50%;}
.mainebox{height: 100vh;} 
.rightpart{height:100%; width: calc(100% - 75px);}
.menuopened .rightpart{width:100%; } 
.mainpart{padding: 15px;}
.menupart{overflow: auto;}
.logout{position: initial;}
.closemenum{display: block;}
.menupart{background: var(--primary); width: 100%;}

}



/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
.leftpart{width: 75px;}
.leftpartcollapes {width: 60%;}

}

/* Landscape */
@media only screen 
and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .leftpart{width: 75px;}
    .leftpartcollapes{width: 50%;}


    
}

@media only screen 
and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
      .customewidthtab{width: 15%;}
  }
