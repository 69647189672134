/* 
font-family: 'NuberNext-DemiBold', sans-serif;
font-family: 'Nurom-Bold', sans-serif; 
font-family: 'NuberNext-Regular', sans-serif;
*/
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');

:root {
--white:#ffffff;
--primary:#FFF7DD;
/* --primary:#d9b02b; */
--box_colr: #F6F9FF;
--aaction_box: #1abc9c;
--blue2:#004588;
--blue3:#30528B;
--blue4:#3E77C1;
--blue5:#31538B;
--purple3:#473691;
--purple4:#5F50A5;
--purple5:#31217A;
--text_colr_dark:#26244A;
--text_color_light: #768088;
--graylight:#687681;
--line_colr: #a9dcd2;
--labelcolor:#AAB7C1;
--overlightcolor:#1E314E;
--greenbtn:#CFF5DA;
--greencolor:#01A52D;
--redbtn:#F9DCDC;
--redcolor:#FF0000;
--orangebtn: #F9E6DC;
--orangecolor: #FF8000;
--greylight:#EEF3FE;
--goldenshade:#8B5B06;
--goldenshade2:#EEBC63;
--purpleshade:#1E0F60;
--purpleshade2:#877AC1;
--greenshade:#04574C;
--greenshade2:#69B5AA;
--green3:#258477;
--green4:#00B424;
--green5:#136F62;
--goldenlight:#8B7106;
--goldenlight2:#EED363;
--gray2:#D2E9FF;
--gray3:#E2ECFF;
--gray4:#F6F9FF;
--gray5:#748FBC;
--black:#000;
--fontsemibold:NuberNext-DemiBold;
--fontregular:NuberNext-Regular;
--fontbold:Nurom-Bold;
--nuberNextLight: nubernextlight;

}
.dark-text{color: var(--text_colr_dark) !important;}
.box_colr{background:#F6F9FF !important;}
.aaction_box{background: #0C2A5C !important;}
.text_colr_dark{background:#252D33 !important;}
.text_color_light {background: #768088 !important;}
.line_colr{background: #D29C3C !important;}
.labelcolor{background: #AAB7C1 !important;}
.overlightcolor{background:#1E314E !important;}
.greenbtn{background:#CFF5DA !important;}
.greencolor{background:#01A52D !important;}
.redbtn{background:#F9DCDC !important;}
.redcolor{background:#FF0000 !important;}
.orangebtn{background: #F9E6DC !important;}
.orangecolor{background: #FF8000 !important;}
.greylight{background:#EEF3FE !important;}
.gray2{background:#D2E9FF !important;}
.gray3{background: #E2ECFF !important;}
.goldenshade{background: #8B5B06 !important}
.purpleshade{background:#1E0F60 !important}
.greenshade{background:#04574C !important}
.greenlight{background:#04574C !important}
.goldenlight{background:#8B7106 !important}


body{padding: 0; margin: 0;font-family: 'Montserrat', sans-serif !important; background: none;}
.container{width: 90%; margin:auto}
p{font-size: 16px;}
p:focus{outline: none;}
a{text-decoration: none;}
.form-group{position: relative;}
.btn{border-radius: 10px; font-style: normal; font-family: 'Montserrat', sans-serif !important; }
.btn-dark{background: var(--black) !important; color: var(--white) !important;}
.btn-dark:hover{background-color: #547DBE !important; border-color: #547DBE;}
.btn-outline-dark{border-color: black !important;}
.btn-outline-dark:hover{color: var(--white) !important;}
a:hover{color: var(--line_colr) !important; text-decoration: none;}
.form-group label{color: #989BAC;  font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; margin-bottom: 3px;}
.form-control{ border:1px solid #B5BBCD; border-radius: 3px; padding: 10px; font-size: 16px; color:var(--black) !important; background-color: transparent;}
.form-control:focus{ background-color: transparent !important; border-color: #000; box-shadow: none;}
select.form-control{background:url(assets/images/icons/userdrop.svg) no-repeat right  var(--white) !important; background-position-x: 244px;     background-size: 30px 15px !important; }
.breadcrumb-item{cursor: pointer;}
select.form-control:disabled{color: #000;}
.loginbox .form-control{color:var(--white) !important}
.form-check-input{background-color: transparent;}
.viewInpBox{padding: 10px; color:var(--black) ; font-size: 15px; background: var(--gray4); border-radius: 5px;}
.clientsName{list-style: none; padding: 0; margin: 0; display: flex; align-items: center; flex-wrap: wrap; gap:10px; margin-bottom: 25px;}
.clientsName li{padding: 10px; color:var(--black) ; font-size: 15px; background: #ffe0b7; border-radius: 5px;}
.multiselect-dropdown .dropdown-btn{border:1px solid #B5BBCD !important; border-radius: 3px !important; padding: 10px !important; font-size: 16px !important; }
.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before{top: 80% !important; }
.multiselect-dropdown .dropdown-btn .dropdown-multiselect--active .dropdown-multiselect__caret{top: 18% !important;}

/* ---------------Fresh Css --------------------- */

.tablelist{width: 100%; border-collapse: collapse;}
.tablelist tr td{padding: 13px 10px; border: none;}
.tablelist tr th{padding: 5px 10px;}

.tablelist thead tr td{color: var(--black); font-weight:600 !important;}
.tablelist tr td{text-align: left; vertical-align: baseline;  font-family: 400 !important; color: var(--text_color_light);}
.tablelist tr td:nth-child(1){padding-left: 24px;}
.tablelist tbody{background:var(--box_colr);  font-weight: 500;}
.tablelist tbody tr:nth-child(even){background: #fff;}
.tablelist thead{background:#f1f1f140;  font-weight: 500;}
.tablelist tbody tr{transition: all .3s ease-in-out; cursor: pointer; border-bottom: 1px solid #f1f1f1;}

.mat-header-cell{font-size: 14px !important;}

.tablelist tbody tr:hover{background: var(--primary) !important;}
.tablelist tbody tr:hover td{color: var(--black);}
.tablelist tbody tr:hover td i{color:var(--black) !important;}
table.dataTable{width: 100% !important; margin-top: 20px !important;}
table.dataTable thead th, table.dataTable thead td{white-space: pre !important;}
.dataTables_length label, .dataTables_filter label{font-size:1rem; text-transform: capitalize;}
.dataTables_info, .paginate_button{font-size:1rem;}
.dataTables_length select, .dataTables_filter input{outline:none !important}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{background: var(--black) !important; color: #fff !important; border: none !important;}
table.dataTable thead th, table.dataTable thead td{padding: 10px 20px !important;}
ul.addaccount{list-style: none; padding: 0; margin:0; display: flex; justify-content: flex-end;}
ul.addaccount li{text-align: right; margin-left: 30px;}
ul.addaccount li button{white-space: pre;}
.tooltipboxpass{ margin: auto; height: 100%;  margin-top: 7px !important;  margin-right: 10px;}
.toolboxinn{position: absolute;
width: 150px;

top: 0px;
right: 35px; opacity: 0; visibility: hidden;
margin: auto; transition: all .3s ease-in-out;
z-index: 1;}
.discriprionWidth{width: 350px; word-break: break-all;}
.tooltipboxpass i{cursor: pointer; width: 20px; height: 10px; line-height: 30px;}
.toolboxinn .innbox2{position: relative;  background: var(--primary); border: 1px solid var(--black);  padding: 5px; border-radius: 3px; text-align: left;}
.toolboxinn .innbox2 p{font-size: 10px;}
.toolboxinn .innbox2::before{position: absolute; content: '';  width: 10px; height: 10px; right: -20px; top: 5px; border-top: 10px solid transparent;
border-right: 10px solid transparent;
border-bottom: 10px solid transparent;
border-left: 10px solid  var(--black);}
.tooltipboxpass:hover .toolboxinn{opacity: 1; visibility: visible;}
/* .contentbox{width: 100%; background: var(--white); box-shadow: 0px 0px 12px 0px #0000001c; padding: 15px; border-radius: 3px;}
 */
 .contentbox{width: 100%; background: var(--white); border-radius: 14px; background: #FFF;  box-shadow: 0 0 7px 6px rgba(0, 0, 0, 0.06);  padding: 30px;} 

.breadcrumbMaine{background: var(--white); padding: 12px;
border-radius: 3px;
box-shadow: 0px 0px 12px 0px #0000001c; margin-top: 15px;}

.addInvoice {width: 50%;}

.tblePreview{border-collapse: collapse; width: 100%;}
.tblePreview tr td{padding: 5px; border: 1px solid var(--gray2);}
.tblePreview tr td:nth-child(1){color: var(--black) !important; font-weight: 600;}
.addlrinvopice .dataTables_filter{display: none;}

.dt-buttons button{background: var(--black); border: 1px solid var(--black); outline: none; padding:5px 15px; color: var(--white); border-radius: 4px;}
.dt-buttons button:hover{background: rgb(18, 18, 18) !important;}

.customeMultiSelect{width: 100%; position: relative;}
.customeMultiSelect .multiSelectDrop{width: 100%; padding: 10px; height: auto; max-height: 200px; overflow: auto; background: var(--white);}
.multiSelectDrop ul{list-style: none; padding: 0; margin: 0;}
.multiSelectDrop ul li label{padding-left: 25px; position: relative; cursor: pointer;  width: 100%;}
.multiSelectDrop ul li label::before{content: '';  font-family: "Font Awesome 5 Free"; font-weight: var(--fa-style,900); color: var(--green4); position: absolute; left: 0; top:0; margin: auto;}

.multiSelectDrop ul li input:checked + label::before{content: '\f00c';}


/* ---------------------------- New Detail Page ------------------------- */

.detailsBox{width: 100%; background-color: #FFF6EA; border-radius: 7px; padding: 25px; padding-bottom: 0;}
.detailsBox h4{font-size: 24px; color: #26244A; font-weight: 700; margin-bottom: 15px;}
.detailsBox label{color: #5C606D; font-size: 17px; font-weight: 600;}
.detailsBox h5{color: #26244A; font-size: 18; font-weight: 600; margin-bottom: 25px;}

@media only screen and (min-width:320px) and  (max-width:480px) {
ul.addaccount{margin-top: 10px;}

}


/* ---------------- loading slips --------------------- */
.noDataScreen{width: 100%; height: calc(100vh - 100px);  display: grid; place-items: center;}
.noDataScreen h2{color: #5C606D;font-size: 32px; font-style: normal; font-weight: 600; line-height: normal;}
.loadingSlip h3{color: #26244A; font-size: 18px; font-style: normal; font-weight: 700; line-height: normal;}
.loadingSlip p{color: #5C606D; font-size: 16px; font-style: normal; font-weight: 500; line-height: normal;}
.addexpenceLoading .mat-dialog-container{padding: 0; border-radius: 16px;}
.addexpenceLoading .header{display: flex; align-items: center; border-bottom: 2px solid #E3E6EF; padding: 15px;}
.addexpenceLoading .header h1{flex: 1;color: #000; font-size: 24px; margin-bottom: 0; font-style: normal; font-weight: 700; line-height: normal; text-align: center;}
.addexpenceLoading .header button{width: 35px; height: 35px; background: none; border: 0; outline: 0;}
.addexpenceLoading .header button img{object-fit: contain;}
.uploadImgNewDesign{width: 100%; border: 1px solid #f1f1f1; border-radius: 8px; display: grid; place-items: center; aspect-ratio: 1/1; background-color: #b7b7b7;}
.uploadImgNewDesign label{background: #000; padding: 12px 35px; font-size: 17px; color: #fff; font-weight: 500; cursor: pointer; border-radius: 5px;}
.uploadImgNewDesign img{width: 200px; height: 200px; object-fit: contain;}



.vehicleDetails{width: 100%; border-radius: 5px; padding: 5px;}
.vehicleDetails table {width: 100%;}
.vehicleDetails table thead tr td{color: #231F20; font-size: 16px; font-style: normal; font-weight: 400; line-height: normal; border-bottom: 2px solid #E4DABD;}

.vehicleDetails table tbody tr td{color: #231F20; font-size: 16px; font-style: normal; font-weight: 500; line-height: normal;}
.vehicleDetails table tr td{padding: 15px;}
.whatdoyou{color: #777; padding:5px 10px; margin-bottom: 0;}
