@font-face {
  font-family: 'NuberNext-DemiBold';
  src: url('assets/fonts/NuberNext-DemiBold.woff2') format('woff2'),
       url('assets/fonts/NuberNext-DemiBold.woff') format('woff'),
       url('assets/fonts/NuberNext-DemiBold.ttf')  format('truetype');
}
@font-face {
  font-family: 'Nurom-Bold';
  src: url('assets/fonts/Nurom-Bold.woff2') format('woff2'),
       url('assets/fonts/Nurom-Bold.woff') format('woff'),
       url('assets/fonts/Nurom-Bold.ttf')  format('truetype');
}
@font-face {
  font-family: 'NuberNext-Regular';
  src: url('assets/fonts/NuberNext-Regular.woff2') format('woff2'),
       url('assets/fonts/NuberNext-Regular.woff') format('woff'),
       url('assets/fonts/NuberNext-Regular.ttf')  format('truetype');
}

@font-face {
  font-family: 'nubernextlight';
  src: url('assets/fonts/nubernext-light-webfont.woff2') format('woff2'),
       url('assets/fonts/nubernext-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/* body {
  font-family: 'NuberNext-DemiBold', sans-serif;
  font-family: 'Nurom-Bold', sans-serif;
} */
